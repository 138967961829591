import React, {ChangeEvent, useEffect, useState} from 'react';
import DatePickerRange from '../../components/date-picker/DatePicker';
import {BookRankingViewModel} from './BookRankingViewModel';
import {Datum} from './models/BookRankingResponseModel';
import BookRankingTable from '../../components/table-list/BookRankingTable';
import {useRecoilState} from "recoil";
import {bookRankingDataState, getProductsByYearDataState} from "../../atoms/BookRankingAtoms";
import Loader from '../../components/loader/Loader';
import {ApexOptions} from "apexcharts";
import PieChart from "../../components/pie-chart/PieChart";
import moment from "moment";
import LinesChart from '../../components/lines-chart/LinesCharts';
// import LinesChart from "../../components/lines-chart/LinesCharts.tsx";

const model = BookRankingViewModel.instance;

const BookRanking = () => {
    const [bookRanking, setBookRanking] = useRecoilState(bookRankingDataState);
    const [productDatabyYearly, setProductDataByYearly] = useRecoilState(getProductsByYearDataState)
    const [page, setPage] = useState<number>(1);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [isLoading, setIsLoading] = useState(true); // Loading status
    const [selectedYear] = useState("2023");

    const limit = 10;

    const handleFromDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedDate = e.target.value;
        setFromDate(selectedDate);
    };
    const handleToDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedDate = e.target.value;
        setToDate(selectedDate);
    };
    const clearFilter = () => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        url.searchParams.delete("from");
        url.searchParams.delete("to");
        const newUrl = url.toString();
        window.history.pushState({}, "", newUrl);
        setFromDate("");
        setToDate("");
    };
    const allProductsByYearly = async (selectedYear: any) => {
        try {
            const params = {
                year: selectedYear
            };
            console.log(params, "paramsparams")
            const response = await model.getProductsDetailApiCallFilterByYearly(params);
            console.log(response, "responseresponse")
            setProductDataByYearly(response)
            // setSelectedYear(response)
        } catch (error) {
            console.error("Error:", error);
        }
    }

    useEffect(() => {
        const BookRankingProductsApiData = async () => {
            try {
                const params = {
                    page: page,
                    perPage: limit,
                    from: fromDate,
                    to: toDate,
                };
                const response2 = await model.getBookRanking(params);
                if (response2 && response2.success) {
                    allProductsByYearly(selectedYear)
                }
                setBookRanking(response2);
                setIsLoading(false)
                console.log(response2, "bookRanking2222222222");
            } catch (error) {
                console.error('Error:', error);
            }
        };
        BookRankingProductsApiData();
    }, [page, fromDate, toDate]);

    interface CustomApexOptions {
        title: {
            text: string;
            align: 'center';
        };
        xaxis: {
            categories: any[];
            type: 'category';
            labels: {};
            tickAmount: 'dataPoints';
        };
    }

    const chartData: {
        options: CustomApexOptions;
        series: any[];
    } = {
        options: {
            title: {
                text: 'Monthly Sales and Quantity',
                align: 'center',
            },
            xaxis: {
                categories: productDatabyYearly?.data?.map((item) =>
                    moment().month(item.month_number - 1).format('MMMM')
                ) || [],
                type: 'category',
                labels: {},
                tickAmount: 'dataPoints',
            },
        },
        series: [
            {
                name: 'Total Revenue',
                data: productDatabyYearly?.data?.map((item) => item.total_amount) || [],
            },
            {
                name: 'Total Sales',
                data: productDatabyYearly?.data?.map((item) => item.total_quantity) || [],
            },
        ],
    };
    console.log(chartData, "chartDatafrombook")

    const salesData = bookRanking?.data?.data?.map((item: any) => ({
        name: item.name,
        total_amount: parseFloat(item.product_items.reduce((total: number, product: any) => total + parseFloat(product.sales.total_amount), 0)) || 0,
        total_quantity: parseFloat(item.product_items.reduce((total: number, product: any) => total + parseFloat(product.sales.total_quantity), 0)) || 0,
    })) || [];

    const series = salesData?.map((item: any) => item.total_amount) || []; // Use conditional rendering
    const options: ApexOptions = {
        chart: {
            type: "donut",
        },
        labels: salesData?.map((item: any) => item.name) || [],
        colors: ['#10B981', '#375E83', '#259AE6', '#450098', '#FFA60B', '#980056', '#009700', '#870006', '#669458'],
        legend: {
            show: true,
            position: 'bottom',
        },

        plotOptions: {
            pie: {
                donut: {
                    size: '65%',
                    background: 'transparent',
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        responsive: [
            {
                breakpoint: 2600,
                options: {
                    chart: {
                        width: 380,
                    },
                },
            },
            {
                breakpoint: 640,
                options: {
                    chart: {
                        width: 200,
                    },
                },
            },
        ],
        // tooltip: {
        //     y: {
        //         formatter: function (dataPointIndex) {
        //             const data = salesData[dataPointIndex];
        //             return `Total Amount: ${data.total_amount}<br>Total Quantity: ${data.total_quantity}`;
        //         },
        //     },
        // },
    };

    // @ts-ignore
    return (
        <div className="card-header border-0">
            <div className=" flex  justify-start">
                <h1 className="text-lg text-black">Module : </h1>
                <h1 className="text-lg">Book Ranking </h1>
            </div>
            <div className="card-tools">
                <div className="row flex-wrap my-3">
                    <DatePickerRange
                        from={fromDate}
                        to={toDate}
                        handleFromDateChange={handleFromDateChange}
                        handleToDateChange={handleToDateChange}
                        clearFilter={clearFilter}
                    />
                </div>
            </div>
            {isLoading ? (
                <><Loader/></>
            ) : (

                <>
                    <BookRankingTable
                        data={bookRanking?.data?.data as Datum[]}
                        totalPages={bookRanking?.data?.last_page || 0}
                        currentPage={page}
                        setPage={setPage}
                        fromDate={fromDate}
                        toDate={toDate}
                    />
                </>
            )}
            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                <div className="col-span-12">
                    {/*<ChartOne/>*/}
                    <LinesChart options={chartData.options} series={chartData.series}/>
                </div>
                <div className="col-span-8">
                    <PieChart options={options}
                              series={series}
                    />
                </div>
            </div>

        </div>
    );
};

export default BookRanking;
