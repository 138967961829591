import {Link} from "react-router-dom";
import React from "react";

interface DatePickerRangeProps {
    from: string;
    to: string;
    handleFromDateChange: any;
    handleToDateChange: any;
    clearFilter: any;
}

const DatePickerRange: React.FC<DatePickerRangeProps> = ({
                                                             from,
                                                             to,
                                                             handleFromDateChange,
                                                             handleToDateChange,
                                                             clearFilter
                                                         }) => {

    return (
        <div className=" flex justify-between w-full gap-2 ">
            <div>
                <label className="mb-3 block text-black dark:text-white">
                    From
                </label>
                <div className="relative">
                    <input
                        type="date"
                        className="custom-input-date custom-input-date-1 w-80 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        id="fromDate"
                        name="from"
                        placeholder="from"
                        value={from}
                        onChange={handleFromDateChange}
                    />
                </div>
            </div>
            <div>
                <label className="mb-3 block text-black dark:text-white">
                    To
                </label>
                <div className="relative">
                    <input
                        type="date"
                        className="custom-input-date custom-input-date-1 w-80 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        min={from}
                        id="toDate"
                        name="to"
                        value={to}
                        onChange={handleToDateChange}
                    />
                </div>
            </div>
            <div className="flex flex-wrap gap-5 items-end">
                <Link
                    to="#"
                    className="inline-flex items-center justify-center rounded-md bg-black text-center font-medium text-white hover:bg-opacity-90 xl:px-10 py-2 text-sm h-15" // Adjust the padding and font size here
                >
                    Current Month
                </Link> <Link
                to="#"
                className="inline-flex items-center justify-center rounded-md bg-black text-center font-medium text-white hover:bg-opacity-90 xl:px-10 py-2 text-sm h-15" // Adjust the padding and font size here
            >
                Last Month
            </Link>
                <Link
                    to="#"
                    className="inline-flex items-center justify-center rounded-md bg-black text-center font-medium text-white hover:bg-opacity-90 xl:px-10 py-2 text-sm h-15" // Adjust the padding and font size here
                    onClick={clearFilter}
                >
                    Clear Filters
                </Link>

            </div>
        </div>
    );
};

export default DatePickerRange;
